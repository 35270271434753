import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { registerServiceWorker } from './registerNotifications'
if ('serviceWorker' in navigator) {
  window?.addEventListener('load', () => {
    console.log('ishladi main.tsx !');
    registerServiceWorker()
  })
}
ReactDOM?.createRoot(document?.getElementById('root')!)?.render(<App />)