import{default as Loader}from'./loader/Loader'
import{default as Loading}from'./loader/Loading'
import{default as SessionModal}from'./sessionModal/SessionModal'
import{default as SectionVisibilityDetector}from'./sectionVisibilityDetector/SectionVisibilityDetector'
import{lazy}from'react'
const Icon=lazy(()=>import('./icon/Icon'))
const Button=lazy(()=>import('./button/Button'))
const Navbar=lazy(()=>import('./navbar/Navbar'))
const Roadbar=lazy(()=>import('./navbar/Roadbar'))
const Sidebar=lazy(()=>import('./sidebar/Sidebar'))
const PieChart=lazy(()=>import('./charts/PieChart'))
const TextInput=lazy(()=>import('./input/TextInput'))
const Calendar=lazy(()=>import('./calendar/Calendar'))
const AreaChart=lazy(()=>import('./charts/AreaChart'))
const TextSpan=lazy(()=>import('./textSpan/TextSpan'))
const FileInput=lazy(()=>import('./fileInput/FileInput'))
const CustomSelect=lazy(()=>import('./select/CustomSelect'))
const Pagination=lazy(()=>import('./pagination/Pagination'))
const Urgently=lazy(()=>import('./orderComponents/Urgently'))
const CheckboxInput=lazy(()=>import('./input/CheckboxInput'))
const DraftEditor=lazy(()=>import('./draftEditor/DraftEditor'))
const Progressbar=lazy(()=>import('./progressbar/Progressbar'))
const AsyncSelect=lazy(()=>import('./asyncSelect/AsyncSelect'))
const DataFilters=lazy(()=>import('./dataFilters/DataFilters'))
const CountupCard=lazy(()=>import('./countupCard/CountupCard'))
const ScrollToTop=lazy(()=>import('./scrollToTop/ScrollToTop'))
const DeleteAlert=lazy(()=>import('./deleteAlerts/DeleteAlert'))
// const SessionModal=lazy(()=>import('./sessionModal/SessionModal'))
const ProgressText=lazy(()=>import('./progressText/ProgressText'))
const OrderIdInfo=lazy(()=>import('./orderComponents/OrderIdInfo'))
const OrderComment=lazy(()=>import('./orderComponents/OrderComment'))
const PasswordInput=lazy(()=>import('./passwordInput/PasswordInput'))
const ModalTextEditor=lazy(()=>import('./draftEditor/ModalTextEditor'))
const ImageUploadInput=lazy(()=>import('./fileInput/ImageUploadInput'))
const ThreeDotsModal=lazy(()=>import('./threeDotsModal/ThreeDotsModal'))
const CollapseLayout=lazy(()=>import('./orderComponents/CollapseLayout'))
// const SwipeableElement=lazy(()=>import('./swipeableElement/SwipeableElement'))
const OnlineUsersCount=lazy(()=>import('./onlineUsersCount/OnlineUsersCount'))
const ProductsAddTable=lazy(()=>import('./productsAddTable/ProductsAddTable'))
const OrderTopHeadBlock=lazy(()=>import('./orderComponents/OrderTopHeadBlock'))
const OrderInfoCollapse=lazy(()=>import('./orderComponents/OrderInfoCollapse'))
const UploadImageToOrder=lazy(()=>import('./uploadImageToOrder/UploadImageToOrder'))
const NotificationPermission=lazy(()=>import('./notification/NotificationPermission'))
const ComplateOrCancellation=lazy(()=>import('./orderComponents/ComplateOrCancellation'))
const CurrentOrderDetailView=lazy(()=>import('./orderComponents/CurrentOrderDetailView'))
const TableActionButtons=lazy(()=>import('./button/TableActionButtons'))
const NavIconbar=lazy(()=>import('./navbar/NavIconbar'))
const SaveCancelButtons=lazy(()=>import('./button/SaveCancelButtons'))
const CheckingBasco=lazy(()=>import('./orderComponents/CheckingBasco'))
const ProductTableHeadInfo=lazy(()=>import('./productTableHeadInfo/ProductTableHeadInfo'))
const DateFrom=lazy(()=>import('./dateFrom/DateFrom'))
const CloseAddItemModalButton=lazy(()=>import('./button/CloseAddItemModalButton'))
const OrderImagesView=lazy(()=>import('./orderComponents/OrderImagesView'))
const CarKubCalculator=lazy(()=>import('./carKubCalculator/CarKubCalculator'))
export {
  Icon,
  Loader,
  Loading,
  Navbar,
  Button,
  Roadbar,
  Sidebar,
  TextSpan,
  DateFrom,
  Calendar,
  Urgently,
  PieChart,
  AreaChart,
  FileInput,
  TextInput,
  Pagination,
  NavIconbar,
  DraftEditor,
  AsyncSelect,
  DataFilters,
  CountupCard,
  DeleteAlert,
  Progressbar,
  OrderIdInfo,
  ScrollToTop,
  ProgressText,
  CustomSelect,
  OrderComment,
  SessionModal,
  PasswordInput,
  CheckingBasco,
  CheckboxInput,
  ThreeDotsModal,
  CollapseLayout,
  ModalTextEditor,
  OnlineUsersCount,
  ProductsAddTable,
  ImageUploadInput,
  SaveCancelButtons,
  // SwipeableElement,
  OrderInfoCollapse,
  OrderTopHeadBlock,
  TableActionButtons,
  UploadImageToOrder,
  ProductTableHeadInfo,
  ComplateOrCancellation,
  CurrentOrderDetailView,
  NotificationPermission,
  SectionVisibilityDetector,
  CloseAddItemModalButton,
  OrderImagesView,
  CarKubCalculator,
}